import React from "react";
import styled from "styled-components";
import { theme } from "./theme";

export const Card = styled("div")`
  display: flex;
  border-radius: 7px;
  box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
`;

const Pat = styled("div")`
  background-image: ${theme.backgroundImages.gold};
`;

export const PatternCard: React.FC<{ height?: number }> = ({
  height = 691,
  children,
}) => {
  return (
    <Card
      style={{
        width: 400,
        height,
        flexDirection: "column",
        backgroundColor: "#fafafa",
      }}
    >
      {children}
      <Pat
        style={{
          margin: "auto 10px 10px 10px",
          height: "55px",
          backgroundSize: "54.5px",
        }}
      />
    </Card>
  );
};
