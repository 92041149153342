import React from "react";
import { PatternCard } from "../../ui/PatternCard";

const borderRadius = "50%";

interface Props {
  picture: any;
  height?: number;
}

export const ContactCard: React.FC<Props> = ({ picture, children, height }) => {
  return (
    <PatternCard height={height}>
      <div
        style={{
          paddingBottom: 20,
          paddingLeft: 50,
          paddingRight: 50,
          height: 525,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              marginTop: 60,
              marginBottom: 10,
              borderRadius,
              height: 174,
              width: 174,
              border: "2px solid #DDE2E3",
            }}
          >
            <div
              style={{
                border: "6px solid #fff",
                borderRadius,
              }}
            >
              <img
                src={picture}
                style={{
                  borderRadius,
                  verticalAlign: "middle",
                }}
                alt="avatar"
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </PatternCard>
  );
};
