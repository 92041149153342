import React from "react";
import styled from "styled-components";

import { HEADER_HEIGHT } from "../../modules/LandingPage/Hero/components";
import { StickyHeader } from "../../modules/LandingPage/StickyHeader";
import { theme } from "../theme";

interface Props {
  showHeader?: boolean;
}

const Container = styled("div")`
  background-image: ${theme.backgroundImages.blue};
`;

export const CardGridLayout: React.FC<Props> = ({ showHeader, children }) => {
  return (
    <div style={{ width: "100%", height: "100%", color: "#444" }}>
      <Container
        style={{
          paddingTop: showHeader ? HEADER_HEIGHT + 57 : 0,
          justifyContent: "center",
          minHeight: "100%",
          paddingBottom: 100,
        }}
      >
        {showHeader ? <StickyHeader transparent /> : null}
        {children}
      </Container>
    </div>
  );
};
