import React from "react";
import { CardGridLayout } from "../../ui/Layout/CardGridLayout";
import { PrettyLink } from "../../ui/PrettyLink";
import { ContactCard } from "./ContactCard";
import momFace from "./momFace.jpg";
import benFace from "./benFace.png";
import { SEO } from "../../components/seo";
import styled from "styled-components";

const Grid = styled("div")`
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 3em;
  grid-auto-rows: 1fr;
  width: 100%;
  justify-content: center;
`;

export const Contact: React.FC = () => {
  return (
    <CardGridLayout showHeader>
      <SEO
        title="Contact"
        description="Contact Saffron if you have questions or feedback."
      />
      <h1
        style={{
          textAlign: "center",
          marginBottom: 0,
          color: "#444",
          fontWeight: 900,
        }}
      >
        Mother-Son Team
      </h1>
      <h2
        style={{
          textAlign: "center",
          marginTop: 10,
          paddingTop: 0,
          marginBottom: 30,
          color: "#666",
          fontStyle: "normal",
        }}
      >
        Julie &amp; Ben
      </h2>
      <Grid>
        <ContactCard height={590} picture={momFace}>
          <p style={{ lineHeight: "1.875rem" }}>
            I’m the designer and passionate cook behind Saffron. I want to
            change the way you use your recipes so you can spend more time
            cooking. If you have any feedback or need help getting setup, I’d
            love to hear from you.
          </p>
          <p style={{ marginTop: "auto" }}>
            <PrettyLink target="_blank" href="mailto:julie@mysaffronapp.com">
              julie@mysaffronapp.com
            </PrettyLink>
          </p>
        </ContactCard>
        <ContactCard height={590} picture={benFace}>
          <p style={{ lineHeight: "1.875rem" }}>
            As the developer of Saffron, I handle all of the technical details
            and making sure you have a smooth experience. If you run into any
            bugs or issues send me an email to get it fixed.
          </p>
          <p style={{ marginTop: "auto" }}>
            <PrettyLink target="_blank" href="mailto:ben@mysaffronapp.com">
              ben@mysaffronapp.com
            </PrettyLink>
          </p>
        </ContactCard>
      </Grid>
    </CardGridLayout>
  );
};
